import { ASSET_PROGRAM } from '@keyliving/shared-types';
import { determineProgramFromAssetContracts } from '@keyliving/utils';

import { useSelector } from '../redux/hooks';
import { selectors as suiteSelectors } from '../redux/modules/suite';

/**
 * Based on the asset contracts, is the user on the Key Firm plan
 *
 * @returns {Boolean} Return true or false
 */
export default function useIsFirmPlan() {
    const contracts = useSelector(suiteSelectors.selectSuiteContracts);
    const program = determineProgramFromAssetContracts(contracts);

    return program === ASSET_PROGRAM.FIRM;
}
