import { InvoiceLineItem } from '@keyliving/shared-types';

// InvoiceLineItemType is out of date, but to avoid updating types everywhere...
export type InvoiceLineItemType = InvoiceLineItem;

export enum InvoiceActionTypes {
    ON_REQUEST_INVOICE = 'ON_REQUEST_INVOICE',
    ON_REQUEST_INVOICE_FAIL = 'ON_REQUEST_INVOICE_FAIL',
    ON_REQUEST_INVOICE_SUCCESS = 'ON_REQUEST_INVOICE_SUCCESS',
    ON_REQUEST_PAYMENT_HISTORY = 'ON_REQUEST_PAYMENT_HISTORY',
    ON_REQUEST_PAYMENT_HISTORY_FAIL = 'ON_REQUEST_PAYMENT_HISTORY_FAIL',
    ON_REQUEST_PAYMENT_HISTORY_SUCCESS = 'ON_REQUEST_PAYMENT_HISTORY_SUCCESS',
}

export type PaymentHistoryType = {
    due_date: string;
    id: string;
    status: string;
    total: number;
};

export type ItemType = {
    amount: number;
    name: string;
};

export type BreakdownType = {
    header: string;
    items: ItemType[];
    total: number;
};

export type InvoiceState = {
    breakdown: BreakdownType[];
    created_at: string;
    due_date: string;
    error?: string;
    id: string;
    invoiceLineItems: InvoiceLineItem[];
    memo: string | null;
    paymentHistory: PaymentHistoryType[];
    reference_id: number;
    subtotal: number;
    taxes: number;
    total: number;
    type: string;
    user_id: string;
};
