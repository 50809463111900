import { fetch } from '../../../lib/fetch';
import { getInvoiceBreakdown } from '../../../lib/helpers';
import { AppThunk } from '../../store';
import { INVOICE_SECTIONS } from './constants';
import { type InvoiceState, type PaymentHistoryType, InvoiceActionTypes } from './types';

const onRequestInvoice = () => ({
    type: InvoiceActionTypes.ON_REQUEST_INVOICE,
});

const onRequestInvoiceSuccess = (payload: InvoiceState) => ({
    type: InvoiceActionTypes.ON_REQUEST_INVOICE_SUCCESS,
    payload,
});

const onRequestInvoiceFail = (payload: string) => ({
    type: InvoiceActionTypes.ON_REQUEST_INVOICE_FAIL,
    payload,
});

const onRequestPaymentHistory = () => ({
    type: InvoiceActionTypes.ON_REQUEST_PAYMENT_HISTORY,
});

const onRequestPaymentHistorySuccess = (payload: {
    id: string;
    paymentHistory: PaymentHistoryType[];
}) => ({
    type: InvoiceActionTypes.ON_REQUEST_PAYMENT_HISTORY_SUCCESS,
    payload,
});

const onRequestPaymentHistoryFail = (payload: string) => ({
    type: InvoiceActionTypes.ON_REQUEST_PAYMENT_HISTORY_FAIL,
    payload,
});

const requestInvoiceData = (): AppThunk<Promise<void>> => {
    return async (dispatch, getState) => {
        dispatch(onRequestInvoice());

        const {
            invoice: { id },
        } = getState();
        const params = new URLSearchParams({ id });

        return fetch(`${process.env.REACT_APP_API_SERVICE_ENDPOINT}/invoice?${params.toString()}`, {
            method: 'GET',
        })
            .then(({ data }) => {
                const breakdown = [];
                INVOICE_SECTIONS.forEach(({ header, items }) => {
                    breakdown.push(getInvoiceBreakdown(header, items, data.invoiceLineItems));
                });
                dispatch(onRequestInvoiceSuccess({ ...data, breakdown }));
            })
            .catch(() => {
                dispatch(onRequestInvoiceFail('Cannot find invoice'));
            });
    };
};

const requestInvoiceDataByInvoiceId = (
    invoiceId: string
): AppThunk<Promise<InvoiceState | null>> => {
    return async (dispatch) => {
        dispatch(onRequestInvoice());

        const params = new URLSearchParams({ id: invoiceId });

        return fetch(`${process.env.REACT_APP_API_SERVICE_ENDPOINT}/invoice?${params.toString()}`, {
            method: 'GET',
        })
            .then(({ data }) => {
                const breakdown = [];
                INVOICE_SECTIONS.forEach(({ header, items }) => {
                    breakdown.push(getInvoiceBreakdown(header, items, data.invoiceLineItems));
                });

                return { ...data, breakdown };
            })
            .catch(() => {
                dispatch(onRequestInvoiceFail('Cannot find invoice'));
                return null;
            });
    };
};

const requestPaymentHistoryData = (): AppThunk<Promise<void>> => async (dispatch, getState) => {
    dispatch(onRequestPaymentHistory());

    const {
        auth: {
            user: { id: userId },
        },
    } = getState();
    const params = new URLSearchParams({ userId });

    return fetch(
        `${process.env.REACT_APP_API_SERVICE_ENDPOINT}/invoice/user?${params.toString()}`,
        { method: 'GET' }
    )
        .then(({ data }) => {
            const paymentHistory: PaymentHistoryType[] = Object.values(data);
            const id: string = paymentHistory[0]?.id;
            dispatch(onRequestPaymentHistorySuccess({ paymentHistory, id }));
        })
        .catch(() => {
            dispatch(onRequestPaymentHistoryFail('Cannot find payment history'));
        });
};

export const actions = {
    requestInvoiceData,
    onRequestInvoice,
    onRequestInvoiceSuccess,
    onRequestInvoiceFail,
    requestPaymentHistoryData,
    onRequestPaymentHistory,
    onRequestPaymentHistorySuccess,
    onRequestPaymentHistoryFail,
    requestInvoiceDataByInvoiceId,
};
