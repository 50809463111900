import {
    type AssetContract,
    ASSET_PROGRAM,
    CONTRACT_CONDITION_TYPE,
} from '@keyliving/shared-types';

/**
 * Given an array of Asset Contracts, determine the Key program that
 * the asset is in.
 */
export default function determineProgramFromAssetContracts(
    contracts?: AssetContract[]
): ASSET_PROGRAM {
    const isFirm = contracts?.find(
        (contract) => contract.type === CONTRACT_CONDITION_TYPE.EQUITY_GROWTH_ANNUAL_EFFECTIVE
    );

    return isFirm ? ASSET_PROGRAM.FIRM : ASSET_PROGRAM.FLEX;
}
